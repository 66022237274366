<template>
	<div class="studyWrap">
		<div class="bottomWrap main">
			<div class="studyLogtit clearfix">
				<h6>我的考试</h6>
			</div>
			<div class="MyExamtable">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="zh_name" label="认证" width="160">
					</el-table-column>
					<el-table-column prop="name" label="考试科目">
					</el-table-column>
					<el-table-column label="考试时间" width="220">
						<template slot-scope="scope">
							<p>
								开始时间：{{
									scope.row.start_time
										| formatimes('yyyy-MM-dd hh:mm')
								}}
							</p>
							<p>
								结束时间：{{
									scope.row.end_time
										| formatimes('yyyy-MM-dd hh:mm')
								}}
							</p>
						</template>
					</el-table-column>
					<el-table-column label="考试地点">
						<template slot-scope="scope">
							<div class="addressdiv">
								<p :title="scope.row.address_name">
									{{ scope.row.address_name }}
								</p>
								<p :title="scope.row.address">
									{{ scope.row.address }}
								</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="报名时间" width="140">
						<template slot-scope="scope">
							<p class="baoming">
								{{
									scope.row.add_time
										| formatimes('yyyy-MM-dd')
								}}<span
									v-if="
										scope.row.status == 4 ||
										scope.row.resit == 1
									"
									>{{
										scope.row.status == 4
											? '延期'
											: scope.row.resit == 1
											? '补考'
											: ''
									}}</span
								>
							</p>
						</template>
					</el-table-column>
					<!--{{ scope.row.paper_total_score  }}-->
					<el-table-column label="考试信息" width="230">
						<template slot-scope="scope">
							<template
								v-if="
									scope.row.status == 0 ||
									scope.row.status == 3 ||
									scope.row.status == 4 ||
									scope.row.status == 5 ||
									scope.row.status == 7
								"
							>
								{{
									scope.row.status === 0
										? '已报名'
										: scope.row.status === 3
										? '缺考'
										: scope.row.status === 4
										? '延期'
										: scope.row.status === 5
										? '已取消报名'
										: scope.row.status === 7
										? '待批阅'
										: ''
								}}
							</template>
							<template v-else>
								<template
									v-if="
										scope.row.answer_id != 0 &&
										scope.row.publish == 1
									"
								>
									<template v-if="scope.row.hide_grades == 0">
										<span
											>成绩：{{
												scope.row.status == 7
													? ''
													: scope.row.total_score
											}}</span
										>
									</template>
									<template v-else>成绩已发布</template>
									<template
										v-if="scope.row.display_exam == 1"
									>
										<span style="margin-left: 15px">
											{{
												scope.row.status === 0
													? '已报名'
													: scope.row.status === 1
													? '合格'
													: scope.row.status === 2
													? '不合格'
													: scope.row.status === 3
													? '缺考'
													: scope.row.status === 4
													? '延期'
													: scope.row.status === 5
													? '已取消报名'
													: scope.row.status === 7
													? '待批阅'
													: ''
											}}
										</span>
									</template>
								</template>
							</template>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="146">
						<template slot-scope="scope">
							<template v-if="btnshowFlag">
								<el-button
									round
									disabled
									class="disabledBtn"
									v-if="scope.row.status === 5"
									>进入考试</el-button
								>
								<template v-else>
									<template
										v-if="
											scope.row.start_time - nowtime > 0
										"
									>
										<el-button
											round
											disabled
											class="disabledBtn"
											v-if="
												scope.row.start_time - nowtime >
												2400
											"
											>考试未开始</el-button
										>
										<el-button
											class="blueBtn"
											round
											@click="JumpExam(scope.row)"
											v-else
											>进入考试</el-button
										>
									</template>
									<template
										v-if="
											nowtime - scope.row.start_time >
												0 &&
											scope.row.end_time - nowtime > 0
										"
									>
										<el-button
											class="blueBtn"
											round
											@click="JumpExam(scope.row)"
											>进入考试</el-button
										>
									</template>
									<template
										v-if="nowtime - scope.row.end_time > 0"
									>
										<el-button
											round
											disabled
											class="disabledBtn"
											>考试已截止</el-button
										>
									</template>
								</template>
							</template>
							<template v-else>
								<el-button
									v-if="
										nowtime >= scope.row.print_start_time && nowtime <= scope.row.print_end_time
									"
									class="blueBtn"
									style="margin-left: 0; margin-top: 10px"
									round
									@click="JumpExamTicket(scope.row)"
									>下载准考证</el-button
								>
							</template>
						</template>
					</el-table-column>
				</el-table>
				<div
					style="
						text-align: right;
						margin-top: 16px;
						padding-right: 34px;
						padding-bottom: 20px;
					"
				>
					<el-pagination
						:current-page="pageNumber"
						:page-sizes="pageSizes"
						:page-size="pageSize"
						:total="totalCount"
						:layout="layout"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { xiaomilist } from './assets/api';
export default {
	name: 'MyExam',
	data() {
		return {
			info: {
				name: '',
				faculty_name: '',
				enter_year: '',
				major_name: '',
				head_img: '',
			},
			tableData: [],
			pageNumber: 1,
			pageSizes: [20, 50, 100],
			pageSize: 20,
			totalCount: 0,
			layout: 'total, sizes, prev, pager, next, jumper',
			nowtime: 0,
			btnshowFlag: false,
		};
	},
	created() {
		if (
			this.$route.query.hosttype &&
			this.$route.query.hosttype == 'pcclient'
		) {
			sessionStorage.setItem('hosttype', 'pcclient');
		}
	},
	mounted() {
		this.btnshowFlag = sessionStorage.getItem('hosttype') ? true : false;
		window.addEventListener('setItem', (e) => {
			console.log(e);
			if (e.key == 'infos') {
				let info = JSON.parse(sessionStorage.getItem('infos'));
				this.info = {
					name: info.name,
					faculty_name: info.faculty_name,
					enter_year: info.enter_year,
					major_name: info.major_name,
					head_img: info.head_img,
				};
			}
		});
		if (JSON.parse(sessionStorage.getItem('infos'))) {
			let info = JSON.parse(sessionStorage.getItem('infos'));
			this.info = {
				name: info.name,
				faculty_name: info.faculty_name,
				enter_year: info.enter_year,
				major_name: info.major_name,
				head_img: info.head_img,
			};
		}
		this.getList();
	},
	methods: {
		// 展示列表分页切换
		handleSizeChange(val) {
			this.pageSize = val;
			this.pageNumber = 1;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageNumber = val;
			this.getList();
		},
		getList() {
			const params = {
				page: this.pageNumber,
				limit: this.pageSize,
			};
			xiaomilist(params).then((res) => {
				if (res.code == 0) {
					this.tableData = res.data.list;
					this.totalCount = res.data.total;
					this.nowtime = res.data.nowtime;
				}
			});
		},
		JumpExam(data) {
			if (data.answer_end_time > 0) {
				this.$router.push({
					path: '/ExamResult',
					query: {
						answer_id: data.answer_id,
						paper_id: data.paper_id,
						course_paper_id: data.course_paper_id,
					},
				});
			} else {
				this.$router.push({
					path: '/examRequire',
					query: {
						course_id: data.course_id,
						id: data.course_paper_id,
						answer_id: data.answer_id,
						paper_id: data.paper_id,
						cst_id: data.cst_id,
						courseType: 2,
						subject_id: data.subject_id,
					},
				});
			}
		},
		JumpExamTicket(data) {
			this.$router.push({
				path: '/examTicket',
				query: {
					course_paper_id: data.course_paper_id,
				},
			});
		},
	},
};
</script>

<style scoped lang="scss">
.studyWrap {
	.main {
		width: 1300px;
	}
	.studyLogtit {
		width: 100%;
		border-radius: 3px 3px 0 0;
		background: #6d94ff;
		color: #fff;
		height: 56px;
		line-height: 56px;
		padding-left: 20px;
		box-sizing: border-box;
		h6 {
			font-size: 16px;
		}
	}
	.bottomWrap {
		margin-top: 30px;
		border-radius: 3px;
		background: #fff;
	}
}
</style>
