var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"studyWrap"},[_c('div',{staticClass:"bottomWrap main"},[_vm._m(0),_c('div',{staticClass:"MyExamtable"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"zh_name","label":"认证","width":"160"}}),_c('el-table-column',{attrs:{"prop":"name","label":"考试科目"}}),_c('el-table-column',{attrs:{"label":"考试时间","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" 开始时间："+_vm._s(_vm._f("formatimes")(scope.row.start_time,'yyyy-MM-dd hh:mm'))+" ")]),_c('p',[_vm._v(" 结束时间："+_vm._s(_vm._f("formatimes")(scope.row.end_time,'yyyy-MM-dd hh:mm'))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"考试地点"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"addressdiv"},[_c('p',{attrs:{"title":scope.row.address_name}},[_vm._v(" "+_vm._s(scope.row.address_name)+" ")]),_c('p',{attrs:{"title":scope.row.address}},[_vm._v(" "+_vm._s(scope.row.address)+" ")])])]}}])}),_c('el-table-column',{attrs:{"label":"报名时间","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"baoming"},[_vm._v(" "+_vm._s(_vm._f("formatimes")(scope.row.add_time,'yyyy-MM-dd'))),(
									scope.row.status == 4 ||
									scope.row.resit == 1
								)?_c('span',[_vm._v(_vm._s(scope.row.status == 4 ? '延期' : scope.row.resit == 1 ? '补考' : ''))]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"label":"考试信息","width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
								scope.row.status == 0 ||
								scope.row.status == 3 ||
								scope.row.status == 4 ||
								scope.row.status == 5 ||
								scope.row.status == 7
							)?[_vm._v(" "+_vm._s(scope.row.status === 0 ? '已报名' : scope.row.status === 3 ? '缺考' : scope.row.status === 4 ? '延期' : scope.row.status === 5 ? '已取消报名' : scope.row.status === 7 ? '待批阅' : '')+" ")]:[(
									scope.row.answer_id != 0 &&
									scope.row.publish == 1
								)?[(scope.row.hide_grades == 0)?[_c('span',[_vm._v("成绩："+_vm._s(scope.row.status == 7 ? '' : scope.row.total_score))])]:[_vm._v("成绩已发布")],(scope.row.display_exam == 1)?[_c('span',{staticStyle:{"margin-left":"15px"}},[_vm._v(" "+_vm._s(scope.row.status === 0 ? '已报名' : scope.row.status === 1 ? '合格' : scope.row.status === 2 ? '不合格' : scope.row.status === 3 ? '缺考' : scope.row.status === 4 ? '延期' : scope.row.status === 5 ? '已取消报名' : scope.row.status === 7 ? '待批阅' : '')+" ")])]:_vm._e()]:_vm._e()]]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"146"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.btnshowFlag)?[(scope.row.status === 5)?_c('el-button',{staticClass:"disabledBtn",attrs:{"round":"","disabled":""}},[_vm._v("进入考试")]):[(
										scope.row.start_time - _vm.nowtime > 0
									)?[(
											scope.row.start_time - _vm.nowtime >
											2400
										)?_c('el-button',{staticClass:"disabledBtn",attrs:{"round":"","disabled":""}},[_vm._v("考试未开始")]):_c('el-button',{staticClass:"blueBtn",attrs:{"round":""},on:{"click":function($event){return _vm.JumpExam(scope.row)}}},[_vm._v("进入考试")])]:_vm._e(),(
										_vm.nowtime - scope.row.start_time >
											0 &&
										scope.row.end_time - _vm.nowtime > 0
									)?[_c('el-button',{staticClass:"blueBtn",attrs:{"round":""},on:{"click":function($event){return _vm.JumpExam(scope.row)}}},[_vm._v("进入考试")])]:_vm._e(),(_vm.nowtime - scope.row.end_time > 0)?[_c('el-button',{staticClass:"disabledBtn",attrs:{"round":"","disabled":""}},[_vm._v("考试已截止")])]:_vm._e()]]:[(
									_vm.nowtime >= scope.row.print_start_time && _vm.nowtime <= scope.row.print_end_time
								)?_c('el-button',{staticClass:"blueBtn",staticStyle:{"margin-left":"0","margin-top":"10px"},attrs:{"round":""},on:{"click":function($event){return _vm.JumpExamTicket(scope.row)}}},[_vm._v("下载准考证")]):_vm._e()]]}}])})],1),_c('div',{staticStyle:{"text-align":"right","margin-top":"16px","padding-right":"34px","padding-bottom":"20px"}},[_c('el-pagination',{attrs:{"current-page":_vm.pageNumber,"page-sizes":_vm.pageSizes,"page-size":_vm.pageSize,"total":_vm.totalCount,"layout":_vm.layout},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"studyLogtit clearfix"},[_c('h6',[_vm._v("我的考试")])])}]

export { render, staticRenderFns }